import request from '@/utils/http'

/***
 * 系统日志信息
 */
export function queryOperationList(params) {
  return request({
    url: '/log/findByPage',
    method: 'get',
    params
  })
}

// 查询登录日志信息
export function queryLoginList(params) {
  return request({
    url: '/log/findLoginByPage',
    method: 'get',
    params
  })
}

//导出登录日志数据
export function exportLoginLog(params) {
  return request({
    url: '/log/login/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

//导出操作日志数据
export function exportOprerationLog(params) {
  return request({
    url: '/log/login/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

