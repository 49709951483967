// 用于实现页面内table高度自适应
export default {
  data() {
    return {
      _tableHeight: null // 表格自适应后的高度
    }
  },
  mounted() {
    window.addEventListener('resize', this._getTableHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this._getTableHeight)
  },
  created() {
    this._tableHeight = null
  },
  methods: {
    _getTableHeight() {
      this._tableHeight = `${window.innerHeight - this._getExtraHeight()}px` // 通过浏览器高度减去额外高度计算获得当前table高度
      this.$forceUpdate()
    },
    // 获取除表格外其他的高度
    _getExtraHeight() {
      return 0
    }
  }
}
